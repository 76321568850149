import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./SurveyFormComponent.css";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const SurveyFormComponent = () => {
  const [surveyFormData, setSurveyFormData] = useState([]);

  const token = localStorage.getItem("token");

  const { setLoading } = useContext(LoadingContext);

  const history = useNavigate();

  const handleGetData = (e) => {
    setSurveyFormData([
      ...surveyFormData,
      {
        Pregunta: e.target.title,
        Respuesta: e.target.value,
      },
    ]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const uniqueQuestions = [];
    const newSurveyFormData = [];

    for (let i = surveyFormData.length - 1; i >= 0; i--) {
      if (!uniqueQuestions.includes(surveyFormData[i].Pregunta)) {
        uniqueQuestions.push(surveyFormData[i].Pregunta);
        newSurveyFormData.unshift(surveyFormData[i]);
      }
    }

    const body = {
      dataSurvey: newSurveyFormData,
    };

    try {
      fetch(`${BACKEND_API}/api/new-survey`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "Encuesta Creada") {
            history("/admin/crear-credito/validar-documento");
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="survey-form-container">
      <div className="sf-header-title-container">
        <h1>La siguiente encuesta es para mejorar nuestro servicio</h1>
      </div>

      <div className="sf-subtitle-container">
        <p className="sf-subtitle">
          *Una vez la respondas, dejará de mostrarse.
        </p>
      </div>

      <form
        onChange={handleGetData}
        onSubmit={handleSubmit}
        className="sf-form-container"
      >
        <div className="current-step">
          <label>
            ¿Qué aspectos del portal considera más útil y menos útil?
            <div className="choice-input-container">
              <label>
                Menos útil
                <select
                  name=""
                  id=""
                  title="¿Qué aspectos del portal considera más útil y menos útil? - menos útil"
                  required
                >
                  <option value="">--Ninguno--</option>
                  <option value="Simulador">Simulador</option>
                  <option value="Creación de créditos">
                    Creación de Créditos
                  </option>
                  <option value="Consulta de créditos en proceso">
                    Consulta de Créditos en Proceso
                  </option>
                  <option value="Consulta de Créditos en Mora">
                    Consulta de Créditos en Mora
                  </option>
                  <option value="Consulta de Créditos en Proceso de Cancelación Avisado">
                    Consulta de Créditos en Proceso de Cancelación Avisado
                  </option>
                  <option value="Consulta de Créditos próximos a renovar">
                    Consulta de Créditos Próximos a Renovar
                  </option>
                  <option value="Consulta de Certificados de Créditos">
                    Consulta de Certificados de Créditos
                  </option>
                </select>
              </label>
              <label>
                Más útil
                <select
                  name=""
                  id=""
                  title="¿Qué aspectos del portal considera más útil y menos útil? - más útil"
                  required
                >
                  <option value="">--Ninguno--</option>
                  <option value="Simulador">Simulador</option>
                  <option value="Creación de créditos">
                    Creación de Créditos
                  </option>
                  <option value="Consulta de créditos en proceso">
                    Consulta de Créditos en Proceso
                  </option>
                  <option value="Consulta de Créditos en Mora">
                    Consulta de Créditos en Mora
                  </option>
                  <option value="Consulta de Créditos en Proceso de Cancelación Avisado">
                    Consulta de Créditos en Proceso de Cancelación Avisado
                  </option>
                  <option value="Consulta de Créditos próximos a renovar">
                    Consulta de Créditos Próximos a Renovar
                  </option>
                  <option value="Consulta de Certificados de Créditos">
                    Consulta de Certificados de Créditos
                  </option>
                </select>
              </label>
            </div>
          </label>
          <label>
            En una escala del 1 al 5 , donde 1 es muy malo y 5 es muy bueno,
            ¿Cómo calificaría su experiencia general utilizando nuestro portal
            de intermediarios?
            <ul class="sf-likert">
              <li>
                <input
                  type="radio"
                  name="first-likert"
                  title="En una escala del 1 al 5 , donde 1 es muy malo y 5 es muy bueno, ¿Cómo calificaría su experiencia general
            utilizando nuestro portal de intermediarios?"
                  value="1"
                  required
                />
                <label>1</label>
              </li>
              <li>
                <input
                  type="radio"
                  name="first-likert"
                  title="En una escala del 1 al 5 , donde 1 es muy malo y 5 es muy bueno, ¿Cómo calificaría su experiencia general
            utilizando nuestro portal de intermediarios?"
                  value="2"
                  required
                />
                <label>2</label>
              </li>
              <li>
                <input
                  type="radio"
                  name="first-likert"
                  title="En una escala del 1 al 5 , donde 1 es muy malo y 5 es muy bueno, ¿Cómo calificaría su experiencia general
            utilizando nuestro portal de intermediarios?"
                  value="3"
                  required
                />
                <label>3</label>
              </li>
              <li>
                <input
                  type="radio"
                  name="first-likert"
                  title="En una escala del 1 al 5 , donde 1 es muy malo y 5 es muy bueno, ¿Cómo calificaría su experiencia general
            utilizando nuestro portal de intermediarios?"
                  value="4"
                  required
                />
                <label>4</label>
              </li>
              <li>
                <input
                  type="radio"
                  name="first-likert"
                  title="En una escala del 1 al 5 , donde 1 es muy malo y 5 es muy bueno, ¿Cómo calificaría su experiencia general
            utilizando nuestro portal de intermediarios?"
                  value="5"
                  required
                />
                <label>5</label>
              </li>
            </ul>
          </label>
          <label>
            En una escala del 1 al 5, donde 1 es muy difícil y 5 es muy fácil,
            ¿qué tan fácil es utilizar nuestro portal?
            <ul class="sf-likert">
              <li>
                <input
                  type="radio"
                  title="En una escala del 1 al 5, donde 1 es muy difícil y 5 es muy fácil, ¿qué tan fácil es utilizar nuestro portal?"
                  name="second-likert"
                  value="1"
                  required
                />
                <label>1</label>
              </li>
              <li>
                <input
                  type="radio"
                  name="second-likert"
                  title="En una escala del 1 al 5, donde 1 es muy difícil y 5 es muy fácil, ¿qué tan fácil es utilizar nuestro portal?"
                  value="2"
                  required
                />
                <label>2</label>
              </li>
              <li>
                <input
                  type="radio"
                  name="second-likert"
                  title="En una escala del 1 al 5, donde 1 es muy difícil y 5 es muy fácil, ¿qué tan fácil es utilizar nuestro portal?"
                  value="3"
                  required
                />
                <label>3</label>
              </li>
              <li>
                <input
                  type="radio"
                  name="second-likert"
                  title="En una escala del 1 al 5, donde 1 es muy difícil y 5 es muy fácil, ¿qué tan fácil es utilizar nuestro portal?"
                  value="4"
                  required
                />
                <label>4</label>
              </li>
              <li>
                <input
                  type="radio"
                  name="second-likert"
                  title="En una escala del 1 al 5, donde 1 es muy difícil y 5 es muy fácil, ¿qué tan fácil es utilizar nuestro portal??"
                  value="5"
                  required
                />
                <label>5</label>
              </li>
            </ul>
          </label>
          <label>
            ¿Qué funcionalidades adicionales le gustaría ver implementadas en el
            portal para mejorar su eficiencia y experiencia?
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              style={{
                resize: "none",
              }}
              title="¿Qué funcionalidades adicionales le gustaría ver implementadas en el
              portal para mejorar su eficiencia y experiencia?"
              required
            ></textarea>
          </label>
          <label>
            ¿Encuentra alguna dificultad o área de mejora específica al utilizar
            el portal para crear créditos o hacer simulaciones?
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              style={{
                resize: "none",
              }}
              title="¿Encuentra alguna dificultad o área de mejora específica al utilizar
              el portal para crear créditos o hacer simulaciones?"
              required
            ></textarea>
          </label>
          <label>
            ¿Cuál es tu producción mensual aproximada en ventas?
            <select
              name=""
              id=""
              title="¿Cuál es tu producción mensual aproximada en ventas?"
              required
            >
              <option value="">--Ninguno--</option>
              <option value="<500.000.000">Menor de 500.000.000</option>
              <option value="501.000.000-1.000.000.000">
                501.000.000-1.000.000.000
              </option>
              <option value="1.000.000.001-2.000.000.000">
                1.000.000.001-2.000.000.000
              </option>
              <option value="2.000.000.0001-5.000.000.000">
                2.000.000.0001-5.000.000.000
              </option>
              <option value="5.000.000.001-10.000.000.000">
                5.000.000.001-10.000.000.000
              </option>
              <option value="10.000.000.001-20.000.000.000">
                10.000.000.001-20.000.000.000
              </option>
              <option value=">20.000.000.0001">Mayor de 20.000.000.0001</option>
            </select>
          </label>
          <label>
            ¿Qué porcentaje de tu producción mensual es financiada?
            <select
              name=""
              id=""
              title="¿Qué porcentaje de tu producción mensual es financiada?"
              required
            >
              <option value="">--Ninguno--</option>
              <option value="<10%">Menor de 10%</option>
              <option value="11%-20%">11%-20%</option>
              <option value="21%-30%">21%-30%</option>
              <option value="31%-40%">31%-40%</option>
              <option value="41%-50%">41%-50%</option>
              <option value="51%-60%">51%-60%</option>
              <option value="61%-70%">61%-70%</option>
              <option value="71%-80%">71%-80%</option>
              <option value="81%-90%">81%-90%</option>
              <option value="91%-100%">91%-100%</option>
            </select>
          </label>
        </div>
        <div className="fws-btns-container">
          <button type="submit" className="fws-btn" title="Enviar">
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};

export default SurveyFormComponent;
