import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./NavbarMobile.css";

const NavbarMobile = ({ userData, menuData }) => {
  const location = useLocation();

  const [message, setMessage] = useState("");

  const [allUsersList, setAllUsersList] = useState([]);

  const token = localStorage.getItem("token");

  const isAdminPreview = localStorage.getItem("adminPreview");

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      setMessage("buenos dias");
    } else if (currentHour >= 12 && currentHour < 18) {
      setMessage("buenas tardes");
    } else {
      setMessage("buenas noches");
    }

    try {
      fetch(`${BACKEND_API}/api/users`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "Users") {
            const userNoAdmin = data.data.filter(
              (user) => user.profile !== "Admin" || user.profile !== "Gestor"
            );

            const usersNewArr = userNoAdmin.map((user) => {
              return { value: user._id, label: user.name };
            });
            setAllUsersList(usersNewArr);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [token]);

  const handleLoginAsUser = () => {
    if (userId && userId !== "") {
      try {
        
        const body = {
          idUser: userId,
        };
        
        fetch(`${BACKEND_API}/api/change-user`, {
          method: "POST",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "Usuario existe") {
            localStorage.setItem("adminToken", token);
            localStorage.setItem("adminPreview", true);
            localStorage.setItem("token", data.data);
            window.location.reload();
            }
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleLogoutUser = () => {
    try {
      const adminToken = localStorage.getItem("adminToken");
      localStorage.setItem("token", adminToken);
      localStorage.removeItem("adminPreview");
      localStorage.removeItem("adminToken");
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeUser = (e) => {
    setUserId(e.value);
  };

  return (
    <nav className="navbar-mobile-container">
      <div className="nbm-first-half-container">
        <div className="logo-img-container">
          <img src="/assets/logo.webp" alt="" className="logo-img" />
        </div>
        <div className="nbm-greetings">
          Hola {message}, {userData.name}
        </div>
      </div>
      {(userData.profile === "Admin" || userData.profile === "Gestor") && (
        <div className="nbm-admin-mode-container">
          <Select
            options={allUsersList}
            onChange={handleChangeUser}
            placeholder="--Selecciona--"
          />
          <button
            className="nb-sa-btn"
            onClick={handleLoginAsUser}
            title="Acceder a Usuario"
          >
            Acceder Usuario
          </button>
        </div>
      )}
      {isAdminPreview && (
        <div className="nbm-admin-mode-container">
          <button
            className="nb-sa-btn logged"
            onClick={handleLogoutUser}
            title="Cerrar Vista de Usuario"
          >
            Cerrar Vista de Usuario
          </button>
        </div>
      )}
      <div className="nbm-second-half-container">
        <div className="menus-container">
          {menuData.map((option, i) => {
            return (
              <Link
                title={option.title}
                key={i}
                to={option.redirect}
                className={`option-container ${
                  option.redirect === location.pathname && "current-select"
                }`}
              >
                <img
                  src={
                    option.redirect === location.pathname
                      ? option.imgSelect
                      : option.img
                  }
                  alt={option.altImg}
                  className="option-icon"
                />
                <p className="title-option">{option.text}</p>
              </Link>
            );
          })}
        </div>
      </div>
    </nav>
  );
};

export default NavbarMobile;
