import React, { useState } from "react";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./PopupWithFormComponent.css";

const PopupWithFormComponent = ({
  getDataFromCreditTable,
  popupWithFormData,
  hasDescription,
  description,
}) => {
  const [popupFormData, setPopupFormData] = useState({});

  const token = localStorage.getItem("token");

  const handleGetFormData = (e) => {
    setPopupFormData({
      ...popupFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();

    try {
      const body = {
        idCredit: popupWithFormData.credit_id,
        observacion: popupFormData.note,
      };

      fetch(`${BACKEND_API}/api/reason-non-renewal`, {
        method: "PUT",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "informacion de credito actualizada") {
            getDataFromCreditTable([], false, { type: "add_observation" });
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const closePopup = () => {
    getDataFromCreditTable([], false, { type: "add_observation" });
  };

  return (
    <div className="popup-with-form-component">
      <div className="popup-with-form-container">
        <div className="popup-with-form-subcontainer">
          <h1 className="pwf-title">{popupWithFormData.title}</h1>
          {hasDescription && (
            <div className="pwf-desc-container">
              <p className="pwf-desc-txt">{description}</p>
            </div>
          )}
          <form
            action=""
            onChange={handleGetFormData}
            onSubmit={handleSaveChanges}
            className="pwf-form-container"
          >
            <select name="note" id="" className="pwf-input-form">
              <option value="">--Seleccione--</option>
              {popupWithFormData.fields.map((field, i) => {
                return (
                  <option key={i} value={field.value}>
                    {field.txt}
                  </option>
                );
              })}
            </select>
            <div className="pwf-btns-container">
              <button onClick={closePopup} className="pwf-btn cancel-btn">
                Cancelar
              </button>
              <button className="pwf-btn">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopupWithFormComponent;
