import React, { useCallback, useContext, useEffect, useState } from "react";
import { BACKEND_API } from "../../constants/backendApi";
import { useNavigate } from "react-router-dom";

//Style
import "./CreateCreditValidateDocumentBody.css";

//Data
import validateInputs from "../../json/CreateCreditData/validateDocumentInputs.json";

//Components
import FormWithStepsComponent from "../FormWithStepsComponent/FormWithStepsComponent";
import CreditDetailsTableComponent from "../CreditDetailsTableComponent/CreditDetailsTableComponent";

//Context
import { PopupContext } from "../../context/PopupContext";

const CreateCreditValidateDocumentBody = () => {
  const { setShowPopup, setPopupData } = useContext(PopupContext);

  const [latestPage, setLatestPage] = useState("");

  const [maxData, setMaxData] = useState(10);

  const [, setInitPage] = useState(0);

  const [creditList, setCreditList] = useState([]);

  const [completeCreditList, setCompleteCreditList] = useState([]);

  const [totalCredits] = useState(0);

  const [reloadTable, setReloadTable] = useState(false);

  const history = useNavigate();

  const [filterData] = useState({
    type: "",
    document_plate: "",
    advisor: "",
    insurer: "",
    credit_state: "",
    next_to_renew: "",
  });

  const token = localStorage.getItem("token");

  localStorage.removeItem("user_document");

  const tableFields = [
    {
      name: "Placa",
    },
    {
      name: "No. de Documento",
    },
    {
      name: "Fecha de creación",
    },
    {
      name: "Acciones",
    },
  ];

  const completeCreditsTableFields = [
    {
      name: "Placa",
    },
    {
      name: "No. de Poliza",
    },
    {
      name: "Fecha de creación",
    },
  ];

  const getMaxDataValue = (maxDataVal) => {
    setMaxData(maxDataVal);
  };

  const getPaginationData = (initPageVal) => {
    setInitPage(initPageVal);
  };

  const isCreditInProcess = JSON.parse(
    localStorage.getItem("isCreditInProcess")
  );

  const creditProcessType = localStorage.getItem("processType");

  const latestStep = localStorage.getItem("latestStep");

  const handleContinueCreationCredit = useCallback(
    (creditData) => {
      const { CedulaCliente, IdCandidato } = creditData;

      localStorage.setItem("form_id", IdCandidato);

      try {
        fetch(`https://crediseguro-back.click/getDocument/${CedulaCliente}`, {
          method: "GET",
        })
          .then((res) => res.json())
          .then((data) => {
            const userData = data.data;
            if (data.message === "Información obtendida exitosamente") {
              localStorage.setItem("processType", "renovation");
            } else {
              localStorage.setItem("processType", "new-credit");
            }
            try {
              fetch(`${BACKEND_API}/api/credit-ocr?id=${IdCandidato}`, {
                method: "GET",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data.message === "informacion creditos") {
                    const creditType = localStorage.getItem("processType");
                    localStorage.setItem(
                      "userData",
                      JSON.stringify({
                        phone: userData.Phone,
                        email: userData.Email,
                      })
                    );
                    localStorage.setItem("isCreditInProcess", true);
                    localStorage.setItem("latestStep", "attach-policy");
                    history(
                      creditType === "renovation"
                        ? "/admin/crear-credito/verificar-datos"
                        : "/admin/crear-credito/formulario"
                    );
                  }
                });
            } catch (err) {
              console.log(err);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    // eslint-disable-next-line
    []
  );

  const handleCancelCreateCredit = useCallback(
    (creditId) => {
      try {
        const body = {
          id: creditId,
        };
        fetch(`${BACKEND_API}/api/cancel-credit-ocr`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then(() => {
            setReloadTable(!reloadTable);
          });
      } catch (err) {
        console.log(err);
      }
    },
    // eslint-disable-next-line
    [reloadTable]
  );

  useEffect(() => {
    if (creditProcessType === "new-credit") {
      switch (latestStep) {
        case "validate-document":
          setLatestPage("/admin/crear-credito/adjuntar-documento");
          break;
        case "attach-document":
          setLatestPage("/admin/crear-credito/adjuntar-poliza");
          break;
        case "attach-policy":
          setLatestPage("/admin/crear-credito/adjuntar-anexo");
          break;
        case "attach-appendix":
          setLatestPage("/admin/crear-credito/formulario");
          break;
        case "complement-data":
          setLatestPage("/admin/crear-credito/formulario-confirmar");
          break;
        default:
          break;
      }
    } else if (creditProcessType === "renovation") {
      switch (latestStep) {
        case "validate-document":
          setLatestPage("/admin/crear-credito/adjuntar-poliza");
          break;
        case "attach-policy":
          setLatestPage("/admin/crear-credito/adjuntar-anexo");
          break;
        case "attach-appendix":
          setLatestPage("/admin/crear-credito/verificar-datos");
          break;
        case "verify-data":
          setLatestPage("/admin/crear-credito/formulario-renovacion");
          break;
        case "complement-data-renovation":
          setLatestPage("/admin/crear-credito/formulario-renovacion-confirmar");
          break;
        default:
          break;
      }
    }

    setShowPopup(true);
    setPopupData({
      hasTitle: true,
      hasInfoArr: true,
      title: "Tenga en Cuenta",
      infoArr: [
        "* Solo se aceptan créditos para persona natural y pólizas de carácter individual.",
        "* Aplica únicamente para las pólizas todo riesgo auto.",
        "* Para los demás casos, que no se encuentren dentro de las condiciones descritas anteriormente, usted deberá hacer el envío de la documentación a través del siguiente correo electrónico: fabrica@crediseguro.co.",
        "* Tenga en cuenta que si la financiación corresponde a las siguientes aseguradoras: AXA, ALLIANZ, PREVISORA, LIBERTY, SBS, BOLIVAR Y SURA, el tomador en la caratula de la póliza debe ser Crediseguro S.A. De lo contrario usted deberá tramitar el cambio de caratula con la correspondiente aseguradora.",
        isCreditInProcess
          ? "Tienes un crédito pendiente por terminar ¿Deseas continuar?"
          : "",
      ],
      type: isCreditInProcess ? "option" : "",
      showOptionBtn: isCreditInProcess,
      btnData: [
        {
          page: "login",
          type: "redirect",
          action: "redirect",
          text: "Si",
          title: "si",
          hasBlock: false,
          redirect: latestPage,
        },
        {
          page: "login",
          type: "redirect",
          action: "delete-data-ls",
          text: "No",
          title: "no",
          redirect: "/admin/crear-credito/validar-documento",
        },
      ],
    });
    // eslint-disable-next-line
  }, [latestPage]);

  useEffect(() => {
    try {
      fetch(`${BACKEND_API}/api/credit-ocr-in-process`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data && data.data) {
            const creditsNewArr = data.data.map((credit) => [
              {
                field_content: credit.placa ? credit.placa : "Sin Placa",
              },
              {
                field_content: credit.CedulaCliente
                  ? credit.CedulaCliente
                  : "Sin Documento",
              },
              {
                field_content: new Date(
                  credit.FechaCreacion
                ).toLocaleDateString("es-ES"),
              },
              {
                is_action_btn: true,
                btns: [
                  {
                    function: () => handleContinueCreationCredit(credit),
                    title: "Retomar Solicitud",
                    icon_src:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/continue-create-credit-icon.webp",
                    icon_src_hover:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/continue-create-credit-icon-hover.webp",
                  },
                  {
                    function: () =>
                      handleCancelCreateCredit(credit.IdCandidatoSF),
                    title: "Anular Solicitud",
                    buttonType: "danger",
                    icon_src:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/cancel-create-credit-icon.webp",
                    icon_src_hover:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/cancel-create-credit-icon-hover.webp",
                  },
                ],
              },
            ]);

            setCreditList(creditsNewArr);
          }
        });
    } catch (err) {
      console.log(err);
    }

    try {
      fetch(`${BACKEND_API}/api/last-credits-ocr`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "informacion creditos ocr") {
            const creditsCompletedNewArr = data.data.map((credit) => [
              {
                field_content: credit.Placa ? credit.Placa : "Sin Placa",
              },
              {
                field_content: credit.NoPoliza ? credit.NoPoliza : "Sin Poliza",
              },
              {
                field_content: new Date(
                  credit.Ultimamodificacion
                ).toLocaleDateString("es-ES"),
              },
            ]);

            setCompleteCreditList(creditsCompletedNewArr);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [
    reloadTable,
    handleCancelCreateCredit,
    handleContinueCreationCredit,
    token,
  ]);

  return (
    <section className="validate-document-section">
      <div className="validate-document-subcontainer">
        <div className="form-with-steps-supercontainer">
          <FormWithStepsComponent
            inputData={validateInputs.data[0]}
            hasSteps={false}
            hasRestoreBtn={false}
          />
        </div>
        <div className="vd-continuation-table-container">
          <div className="vd-ct-table-container">
            <h1 className="vd-uctt-title">Créditos en revisión</h1>
            <CreditDetailsTableComponent
              getMaxData={getMaxDataValue}
              maxData={maxData}
              getPaginationInit={getPaginationData}
              credits={creditList}
              totalCreditsNum={totalCredits}
              filterData={filterData}
              isPopup={true}
              fields={tableFields}
            />
          </div>
          <div className="vd-ct-table-container">
            <h1 className="vd-uctt-title">Solicitudes completadas</h1>
            <CreditDetailsTableComponent
              getMaxData={getMaxDataValue}
              maxData={maxData}
              getPaginationInit={getPaginationData}
              credits={completeCreditList}
              totalCreditsNum={totalCredits}
              filterData={filterData}
              isPopup={true}
              fields={completeCreditsTableFields}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateCreditValidateDocumentBody;
