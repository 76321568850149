import React from "react";

//Styles
import "./AttachAppendixBody.css";

//Data
import attachAppendixInputData from "../../../json/CreateCreditData/AttachAppendixDataInputs.json";
import attachAppendixObservationData from "../../../json/CreateCreditData/AttachAppendixObservationInputs.json";

//Components
import FormDocsComponent from "../../FormDocsComponent/FormDocsComponent";
import FormWithStepsComponent from "../../FormWithStepsComponent/FormWithStepsComponent";

const AttachAppendixBody = ({ userData }) => {
  return (
    <section className="attach-doc-section">
      <div className="attach-doc-subcontainer">
        <div className="attach-form-supercontainer limit-width">
          <FormDocsComponent inputData={attachAppendixInputData.data[0]} />
        </div>
        {userData?.document === "900697686" && (
          <div className="attach-appendix-form-container">
            <FormWithStepsComponent
              inputData={attachAppendixObservationData.data[0]}
              hasSteps={false}
              hasRestoreBtn={false}
            />
          </div>
        )}
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/woman-deco-img-3.webp"
          className="profile-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default AttachAppendixBody;
